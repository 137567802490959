<template>
    <div>
        <b-overlay :show="show" no-wrap fixed z-index="9999">
            <template v-slot:overlay>
                <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                    <b-spinner type="grow" variant="dark"></b-spinner>
                    <b-spinner small type="grow" variant="dark"></b-spinner>
                </div>
            </template>
        </b-overlay>
        <b-card title="" class="mt-3">
            <!-- <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute"
                style="top:20px; left: 20px;" size="sm"><i class="fa fa-bars"></i></b-button> -->
                <h5 class="font-weight-bold">سفارشات {{items.data && items.data.data != '' && items.data.data[0] ? items.data.data[0].seller.name + ' ' + items.data.data[0].seller.last_name + ' - شرکت : ' + items.data.data[0].seller.company_name : ''}} </h5>
            <b-collapse id="filterBox" class="my-2">
                <b-card>
                    <form @submit.prevent="searchItems()" id="search-item">
                        <div class="row">
                            <div class="col-md-4">
                                <b-form-group label="شماره فاکتور" label-for="title">
                                    <b-form-input id="title" v-model="searchParams.invoice_number"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4">
                                <b-form-group label="وضعیت" label-for="type">
                                    <b-form-select id="type" v-model="searchParams.status">
                                        <b-form-select-option value="0">ثبت شده</b-form-select-option>
                                        <b-form-select-option value="1">در حال جمع آوری</b-form-select-option>
                                        <b-form-select-option value="2">ارسال شد</b-form-select-option>
                                        <b-form-select-option value="3">موجود نیست</b-form-select-option>
                                        <b-form-select-option value="4">تحویل داده شد</b-form-select-option>
                                        <b-form-select-option value="">همه</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <div class="col-md-4">
                                <b-form-group label="تاریخ" label-for="end_date">
                                    <date-picker type="datetime" v-model="searchParams.date"
                                        format="jYYYY-jMM-jDD HH:mm" />
                                </b-form-group>
                            </div>
                        </div>
                        <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
                            جو</button>
                    </form>
                </b-card>
            </b-collapse>
            <b-table v-if="items.data" responsive striped outlined hover :fields="order_table_fields"
                :items="items.data.data">
                <template v-slot:cell(status)="data">
                    <div style="width: 200px;">
                        <p v-if="data.item.status == 'init' " class="p-1 bg-secondary text-center small rounded">
                            ثبت شده</p>
                        <p v-else-if="data.item.status == 'sending' " class="p-1 bg-info text-center small rounded">
                            در حال ارسال</p>
                        <p v-else-if="data.item.status == 'unavailable' "
                            class="p-1 bg-primary text-center small rounded">
                            موجود نیست
                        </p>
                        <p v-else-if="data.item.status == 'sent' " class="p-1 bg-success text-center small rounded">
                            ارسال شد
                        </p>
                    </div>
                </template>
                <template v-slot:cell(invoice_number)="data">
                    <button class="cursor-pointer" @click="showOrderDetails(data.index)">
                        {{ data.item.invoice.invoice_number }}
                    </button>
                </template>

                <template v-slot:cell(created_at)="data">
                    <p v-if="data.item.created_at">{{ data.item.created_at | persianDate}}</p>
                </template>
                <template v-slot:cell(index)="data">
                    {{ data.index + 1  }}
                </template>

                <template v-slot:cell(details)="data">
                    <button class="btn-sm btn btn-info" @click="showOrderDetails(data.index)">
                        <i class="fa fa-info"></i>
                    </button>
                </template>
            </b-table>
            <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
                <span class="text-right">موردی یافت نشد ! </span>
            </div>
            <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
            </pagination>
            <pagination :limit="2" v-else-if="items.data && search" :data="items.data"
                @pagination-change-page="searchItems">
            </pagination>
        </b-card>

        <b-modal id="order-detail" :title="' شماره فاکتور' + ' ' + orderDetail.invoice_number" hide-footer size="xl">
            <form @submit.prevent="orderUpdate" id="order-update">
                <div v-if="orderDetail.orders" class="box-shadow mt-3 rounded pt-1">
                    <b-table responsive striped hover class="mb-0" :fields="order_detail_table_fields"
                        :items="orderDetail.orders">
                        <template v-slot:cell(quantity_send)="data">
                            <div class="d-flex justify-content-start align-items-center">
                                <span class=" cursor-pointer increment p-0 text-center" 
                                    @click="increment(data.index) ">
                                    <i class="fas fa-plus"></i>
                                </span>
                                <span class="border rounded " style="height: 30px; line-height: 5px;"
                                    >
                                    {{data.item.pivot ? data.item.pivot.available_count : 0}}
                                </span>
                                <span class=" cursor-pointer increment p-0 text-center" 
                                    @click="decrement(data.index)">
                                    <i class="fas fa-minus"></i>
                                </span>
                            </div>
                        </template>
                        <template v-slot:cell(quantity)="data">
                            {{data.item.quantity}}
                        </template>
                        <template v-slot:cell(wanted_count)="data">
                            <div class="d-flex justify-content-start align-items-center">
                                <span class=" cursor-pointer increment p-0 text-center" 
                                    @click="incrementWantedCount(data.index) ">
                                    <i class="fas fa-plus"></i>
                                </span>
                                <span class="border rounded " style="height: 30px; line-height: 5px;"
                                    >
                                    {{data.item.pivot ? data.item.pivot.wanted_count : 0}}
                                </span>
                                <span class=" cursor-pointer increment p-0 text-center" 
                                    @click="decrementWantedCount(data.index)">
                                    <i class="fas fa-minus"></i>
                                </span>
                            </div>
                        </template>
                        <template v-slot:cell(title)="data">
                            {{ data.item.variety ? data.item.variety.fullTitle : ''  }}
                        </template>
                        <template v-slot:cell(index)="data">
                            {{ data.index + 1  }}
                        </template>
                        <template v-slot:cell(error)="data">
                            <span v-if="data.item.error == 'false'">ندارد</span>
                            <span v-else-if="data.item.error == 'more'">تعداد درخواستی از فروشنده از تعداد سفارش کاربر
                                بیشتر
                                می باشد</span>
                            <span v-else-if="data.item.error == 'less'">تعداد درخواستی از فروشنده از تعداد سفارش کاربر
                                کمتر
                                می باشد</span>
                            <span v-else>ندارد</span>
                        </template>
                        <template v-slot:cell(price)="data">
                            <b-form-input v-if="data.item.pivot.price != null" :value="data.item.pivot.price | price"
                              @input="value=>{data.item.pivot.price = +value.replace(/\D/g, '')}"></b-form-input>
                          </template>
                    </b-table>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-5 ">
                    <div class="d-flex justify-content-center">
                        <span class="fontWeightBold mt-2 ml-1">
                            وضعیت کلی فاکتور :
                        </span>
                        <b-form-select v-model="orderDetail.status" name="status" style="width: 200px;">
                            <b-form-select-option value="init">ثبت شده</b-form-select-option>
                            <b-form-select-option value="sending">در حال ارسال</b-form-select-option>
                            <b-form-select-option value="unavailable">موجود نیست</b-form-select-option>
                            <b-form-select-option value="sent">ارسال شد</b-form-select-option>
                        </b-form-select>
                        <b-form-group></b-form-group>
                    </div>
                    <div>
                        <button class="btn btn-success" type="submit" :disabled="disabled"> ثبت وضعیت</button>
                    </div>

                </div>

            </form>
        </b-modal>
    </div>
</template>
<script>
    import mixins from './mixins/mixins'
    export default {
        mixins: [mixins],
        data() {
            return {
                title: 'سفارشات',
                url: '/api/admin/sellers',
                order_table_fields: [{
                        key: 'index',
                        label: 'ردیف'
                    },
                    {
                        key: 'invoice_number',
                        label: 'شماره فاکتور'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ سفارش'
                    },
                    {
                        key: 'status',
                        label: 'وضعیت'
                    },
                    {
                        key: 'details',
                        label: 'اقلام'
                    }
                ],
                order_detail_table_fields: [{
                        key: 'title',
                        label: 'عنوان'
                    },
                    {
                        key: 'quantity',
                        label: 'تعداد سفارش کاربر'
                    },
                    {
                        key: 'wanted_count',
                        label: 'تعداد درخواست'
                    },
                    {
                        key: 'quantity_send',
                        label: 'تعداد ارسال'
                    },
                    {
                        key: 'price',
                        label: 'قیمت'
                    },
                ],
                orderItems: [],
                orderDetail: {},
                oldStatus: ''
            }
        },
        mounted() {
            if (this.$route.params.id) {
                this.url = this.url + `/${this.$route.params.id}/provides`
                this.loadItems()
                this.searchParams = {
                    invoice_number: '',
                    status: '',
                    date: ''
                }
            }
        },
        watch: {
            'orderDetail.status': function (newVal, oldVal) {
                this.oldStatus = oldVal
                // console.log(this.oldStatus);
                if(!oldVal) return
                if (newVal == 'sent' && oldVal) {
                    window.swal({
                            // title: "آیتم حذف شود؟",
                            text: "آیا از تغییر وضعیت به ارسال شد اطمینان دارید ؟",
                            icon: "warning",

                            showCancelButton: true,
                            showConfirmButton: true,
                            dangerMode: true,
                            buttons: ["خیر", "بله تغییر کند"]
                        })
                        .then((willDelete) => {
                            if(willDelete == null) {
                               this.orderDetail.status = this.oldStatus
                            }
                        })
                        .catch(() => {

                        })
                }

            }
        },
        methods: {
            showOrderDetails(index) {
                let item = window.clone(this.items.data.data[index])
                item.index = index
                this.orderDetail = item
                this.orderDetail.invoice_number = this.orderDetail.invoice.invoice_number
                this.$root.$emit('bv::show::modal', 'order-detail')
            },
            increment(index) {
                if (this.orderDetail.orders[index].pivot.available_count < this.orderDetail.orders[index].pivot
                    .wanted_count) {
                    this.orderDetail.orders[index].pivot.available_count = this.orderDetail.orders[index].pivot
                        .available_count +
                        1
                }
            },
            decrement(index) {
                if (this.orderDetail.orders[index].pivot.available_count > 0) {
                    this.orderDetail.orders[index].pivot.available_count = this.orderDetail.orders[index].pivot
                        .available_count - 1
                }
            },
            incrementWantedCount(index) {
                this.orderDetail.orders[index].pivot.wanted_count = this.orderDetail.orders[index].pivot.wanted_count +
                    1
            },
            decrementWantedCount(index) {
                if (this.orderDetail.orders[index].pivot.wanted_count > 0) {
                    this.orderDetail.orders[index].pivot.wanted_count = this.orderDetail.orders[index].pivot
                        .wanted_count - 1
                }
            },
            orderUpdate() {
                this.disabled = true
                let form = document.getElementById('order-update')
                let formData = new FormData(form)
                this.orderDetail.orders.map((el, index) => {
                    formData.append('orders[' + index + `][id]`, el.id)
                    formData.append('orders[' + index + `][available_count]`, el.pivot.available_count)
                    formData.append('orders[' + index + `][wanted_count]`, el.pivot.wanted_count)
                    formData.append('orders[' + index + `][price]`, el.pivot.price)
                })
                this.$axios.post(this.$root.baseUrl +
                        `/api/admin/sellers/${this.orderDetail.seller_id}/provides/${this.orderDetail.id}`, formData)
                    .then((response) => {
                        this.$root.success_notification(response.data.message)
                        this.this.items.data.data[this.orderDetail.index].status = this.orderDetail.status
                        this.this.items.data.data[this.orderDetail.index].orders = response.data.data.orders
                    })
                    .catch((error) => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
        }
    }
</script>
<style scoped>
    .box-shadow {
        box-shadow: 5px 9px 25px 6px #0a0a0a21;
        overflow: hidden;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    .increment {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .increment .fas {
        font-size: 11px;
    }
</style>